import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoBlog } from "../../../components/video";
import { GoToPopup } from "../../homepage";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const VidImg = require("../../../assets/img/integration/bamboohr/video_img_bamboo.png");

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/freshservice_blog/1_1.png");
const image2 = require("../../../assets/images/bamboohr_blog/1.png");
const image3 = require("../../../assets/images/bamboohr_blog/2.png");
const image4 = require("../../../assets/images/freshservice_blog/3_3.png");
const image5 = require("../../../assets/images/bamboohr_blog/3.png");
const image6 = require("../../../assets/images/bamboohr_blog/4.png");
const image7 = require("../../../assets/images/bamboohr_blog/5.png");
const image8 = require("../../../assets/images/bamboohr_blog/6.png");
const image9 = require("../../../assets/images/bamboohr_blog/7.png");
const image10 = require("../../../assets/images/bamboohr_blog/8.png");
const image11 = require("../../../assets/images/bamboohr_blog/9.png");
const image12 = require("../../../assets/images/bamboohr_blog/10.png");
const image13 = require("../../../assets/images/bamboohr_blog/11.png");
const image14 = require("../../../assets/images/bamboohr_blog/12.png");
const image15 = require("../../../assets/images/bamboohr_blog/13.png");
const image16 = require("../../../assets/images/freshservice_blog/l_1.png");
const image17 = require("../../../assets/images/freshservice_blog/l_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Free BambooHR Chatbot: Build with Conversational AI"
        description="Quickly build a BambooHR chatbot without coding using Workativ’s platform. Automate workplace support and streamline HR processes with our easy-to-use tools"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            The Ultimate Guide to BambooHR Chatbot 
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3 class="font-section-sub-header-small">
                          Table of contents
                        </h3>
                        <div className="table_contents_link font-section-normal-text-medium">
                          <AnchorLink offset={180} href="#section1">
                            1. What is BambooHR Chatbot?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. How does BambooHR Chatbot works?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What is BambooHR Chatbot used for?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Best BambooHR Chatbot platform
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. AI-powered BambooHR automation with BambooHR
                            Chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. How to Build BambooHR Chatbot Without coding
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section7">
                            7. Business benefits of BambooHR chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section8">
                            8. Conclusion
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section9">
                            9. FAQs
                          </AnchorLink>
                        </div>
                      </section>

                      {/* Header Section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        <p class="font-section-normal-text line-height-2">
                          In a matter of only a few months, the world as we know
                          it has changed drastically, stumbling into survival
                          mode as it struggles to adapt to new realities. This
                          is equally true for the global workforce, as people
                          are learning to deal with rapid shifts, health scares,
                          financial strains, and domestic challenges that can be
                          stressful and confusing. As a result, human
                          resources&nbsp;
                          <a href="https://blog.workativ.com/reinventing-the-role-of-hr-during-the-covid-19-pandemic-business-continuity-planning-chatbots-and-whatnot/">
                            &nbsp;(HR) has become one of the single most
                            important functions for any organization in these
                            unprecedented times. 
                          </a>{" "}
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          While businesses continue to make hard decisions to
                          stay afloat and minimize job losses, HR teams work in
                          tandem with business heads to engage employees and
                          keep up the morale, reconfigure workflows, redeploy
                          talent, and upskill staff to help them stay relevant.
                          It is the HR team that is often tasked with providing
                          much-needed emotional support to employees while
                          ensuring the continued productivity of each
                          individual.   
                        </p>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        {/* Section 1 */}
                        <div id="section1">
                          <h2 className="font-section-sub-header-small-bold">
                            What is the BambooHR Chatbot?
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            BambooHR Chatbot is an{" "}
                            <a href="https://workativ.com/conversational-ai-platform/nocode-chatbot">
                              AI-powered no-code platform
                            </a>
                            for building contextual chatbots with automated
                            workflows for various business apps like{" "}
                            <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                              Slack
                            </a>{" "}
                            or{" "}
                            <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                              Microsoft Teams.
                            </a>
                            Using BambooHR Chatbot, companies benefit by
                            automating repetitive issues, save costs, reduce
                            time to resolution, delivering faster support and
                            more.
                          </p>
                        </div>

                        {/* Section 2 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section2"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How does the BambooHR Chatbot work?    
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                              BambooHR chatbots
                            </a>{" "}
                            use Natural Language Processing to understand a
                            user’s intention or utterances for initiating a
                            conversation with it as well as to extract required
                            data from a user’s query and pass it to the
                            automation to resolve the user’s issue in
                            real-time. So, think of how intelligent chatbots
                            work, but with the BambooHR chatbot, it can not only
                            have those benefits, but also extend the
                            functionalities of the BambooHR tool using advanced
                            integration and workflows. If an HR AI chatbot
                            tackles monotonous chores, HR managers would have
                            more time to concentrate on keeping staff motivated
                            and addressing greater issues such as employee
                            happiness and lowering employee attrition.While the
                            HR staff is dealing with situations that need their
                            attention, the HR chatbot may help team members
                            communicate with one another. When an employee
                            requests a paper, for example, an HR chatbot may
                            provide it in seconds. A manager, on the other hand,
                            would have to search for the paper, which may take
                            several hours. The additional time spent searching
                            for that document may produce stress between the
                            employee and the boss, therefore the chatbot
                            relieves the manager of that burden and enables
                            <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk">
                              proactive involvement.
                            </a>
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            HR Bamboo chatbot applications include:
                          </p>
                          <ul
                            className="font-section-normal-text"
                            style="float: left; width: 100%;"
                          >
                            <li>
                              HR chatbots are being used for recruiting
                              operations.{" "}
                            </li>
                            <li>
                              Using HR chatbots to assist current workers{" "}
                            </li>
                            <li>
                              Using HR chatbots to automate basic HR tasks{" "}
                            </li>
                          </ul>
                        </div>

                        {/* Section 3 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page"
                          id="section3"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            What is BambooHR chatbot used for?
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            If you are one of the HR professionals struggling
                            with the balancing act, a move from spreadsheets and
                            paper-based processes to automated workflows
                            certainly makes sense at this point in time. Here
                            are some reasons why streamlining the workflows with
                            HR automation in the pandemic era would be a good
                            decision for your business. 
                          </p>
                          <h2 class="font-section-normal-text-medium">
                            1. Save time and focus on critical issues 
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            With{" "}
                            <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                              HR automation,
                            </a>{" "}
                            <a href="https://workativ.com/use-cases/payroll-enquiry-automation">
                              it becomes possible to streamline everyday tasks
                              such as creating customized onboarding documents,
                              gathering payroll information,
                            </a>{" "}
                            and more. It cuts the excessive workload on the HR
                            employees and reduces burnout on the mundane
                            administrative work. Rather, they can focus on
                            strategic and critical aspects such as policies and
                            procedures to be implemented on reopening. 
                          </p>
                          <h2 class="font-section-normal-text-medium">
                            2. Accelerate onboarding and offboarding processes 
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                              Modern employee onboarding
                            </a>{" "}
                            software with enterprise-grade compliance simplifies
                            and standardizes HR processes like onboarding and
                            offboarding. Typically, it can handle the
                            nitty-gritty of onboarding and streamline the
                            offboarding process as well.  
                          </p>
                        </div>

                        {/* Section 4 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section4"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Best BambooHR Chatbot platform
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Workativ Assistant is an AI-powered no-code platform
                            for building contextual chatbots with automated
                            workflows for business apps. Workativ Assistant’s
                            chatbots can be easily added to you Slack/Microsoft
                            workspace so that your employees can self-serve
                            their IT issues on-the-go, anytime. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            By integrating BambooHR with Workativ Assistant, you
                            ensure your employees get the IT/HR support they’d
                            love. 
                          </p>
                        </div>

                        <NocodeWrapper />

                        {/* Section 5 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 mb-0 market_wrapper_page"
                          id="section5"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Smarter AI-powered BambooHR automations with
                            BambooHR Chatbot
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            Here are some of the BambooHR tasks
                            that Workativ can take care of for you. 
                          </p>
                          <h2 class="font-section-normal-text-medium ">
                            1. Centralized Employee Database 
                          </h2>

                          <p class="font-section-normal-text line-height-2">
                            By connecting BambooHR with Workativ, keep all your
                            employee-related information in an interactive
                            database that can{" "}
                            <a href="https://workativ.com/assistant/integrations">
                              integrate into other programs.
                            </a>{" "}
                            It updates across the board when information is
                            changed so that everything is up-to-date all the
                            time. You never have to worry about conflicting
                            versions. All the work is done for you. 
                          </p>
                          <ExistingBlogCta
                            ContentCta="Streamline Your HR Operations with BambooHR Chatbot."
                            ButtonText="Book a Demo"
                            isColor="white"
                            backgroundImage={cta_2}
                            mobileBackgroundImage={cta_2_mob}
                          />
                          <h2 class="font-section-normal-text-medium ">
                            2. BambooHR Employee Self-Service {" "}
                          </h2>

                          <p className="font-section-normal-text line-height-2">
                            These days, employees expect on-demand access to the
                            systems they use.  Give them the freedom to{" "}
                            <a href="https://workativ.com/use-cases/leave-management-automation">
                              access their PTO,
                            </a>{" "}
                            employment information, tax details, and so forth
                            via Workativ’s BambooHR self-service chatbot.
                            With Workativ’s 
                            <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                              {" "}
                              BambooHR chatbot
                            </a>
                            , employees can access their profile from anywhere
                            with an Internet connection. They should be able to
                            request time off, review benefits, and more. Invest
                            in a platform like Workativ that enables employees
                            to request time off on the go via chatbot. 
                          </p>
                        </div>

                        {/* Section 6 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section6"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How to Build a BambooHR chatbot
                          </h3>
                          <h3 className="font-section-normal-text-medium">
                            Step 1. Creating a bot workspace
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            When you sign up for{" "}
                            <a href="https://workativ.com/conversational-ai-platform">
                              Workativ Assistant,
                            </a>{" "}
                            you’ll be required to name your workspace. You can
                            provide the name of  your organization as a
                            workspace name.    
                          </p>
                          <img
                            loading="lazy"
                            src={image1}
                            className="mb-5"
                          ></img>
                          <h3 className="font-section-normal-text-medium">
                            Step 2. Download your bot
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Download a prebuilt BambooHR bot from the bot
                            Marketplace    
                          </p>
                          <img
                            loading="lazy"
                            src={image2}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image3}
                            className="mb-5"
                          ></img>
                          <p className="font-section-normal-text line-height-2">
                            So that’s all there is to the initial set up of your
                            workspace. Next, let’s download BambooHR workflows
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Step 3. Setting up a BambooHR app workflow 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-1 pb-0">
                            Lets download BambooHR app workflow from
                            marketplace   
                          </p>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Click on Download from Marketplace  
                          </p>
                          <img
                            loading="lazy"
                            src={image4}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the BambooHR application   
                          </p>
                          <img
                            loading="lazy"
                            src={image5}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the app workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image6}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Connect with your BambooHR account    
                          </p>
                          <img
                            loading="lazy"
                            src={image7}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image8}
                            className="mb-5"
                          ></img>
                          <img
                            loading="lazy"
                            src={image9}
                            className="mb-3"
                          ></img>
                          <img loading="lazy"></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test the app workflow   
                          </p>
                          <img
                            loading="lazy"
                            src={image10}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium">
                            Step 4: Now add workflow to the BambooHR chatbot 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Add app workflow to the dialog conversation  
                          </p>
                          <img
                            loading="lazy"
                            src={image11}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image12}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Provide the inputs & save it   
                          </p>
                          <img
                            loading="lazy"
                            src={image13}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image14}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test your BambooHR chatbot    
                          </p>
                          <img
                            loading="lazy"
                            src={image15}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium mb-0 pb-0">
                            Step 5: Deploy BambooHR chatbot in your favourite
                            chat channel   
                          </h3>
                          <img
                            loading="lazy"
                            src={image16}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image17}
                            className="mb-3"
                          ></img>
                        </div>

                        <div className="build_needs">
                          <div class="col-lg-12 col-md-12 col-12 pl-0 center_features_header">
                            <h2 className="font-section-header mb-3">
                              Steps to build Bamboo HR Chatbot
                            </h2>
                          </div>
                        </div>

                        <VideoBlog
                          videoURL={
                            "https://www.youtube-nocookie.com/embed/0VZl7P8KhLY"
                          }
                          imgURL={VidImg}
                        />

                        {/* Section 7 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 mb-0 market_wrapper_page"
                          id="section7"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Business benefits of&nbsp; BambooHR chatbot with
                            Workativ
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            Now the benefits are enormous when it comes to
                            integrating and automating BambooHR tasks
                            with Workativ. 
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            1. No data loss 
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            As everything is automated, you don’t have to worry
                            about data loss. Workativ’s BambooHR chatbot stores
                            and updates every information that it receives from
                            a user in BambooHR real-time. 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            2. Easy Accessibility and Approval 
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            Workativ’s BambooHR chatbot helps in
                            <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                              &nbsp;easy access to information
                            </a>{" "}
                            from any place across the globe. It helps management
                            in accessing employee attendance records and then
                            approving their leave requests based on that.
                            Meanwhile, employees get notified about their
                            approval/rejection of leave requests and can also
                            track holiday accrual, leave history, etc. via the
                            chatbot. 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            3. All from the comfort of your chat hub  
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            Workativ’s BambooHR AI chatbot proactively resides
                            on your Slack/Microsoft Teams workspace waiting to
                            resolve your employees’ HR queries with BambooHR
                            self-service, 24×7. 
                          </p>
                        </div>

                        {/* Section 8*/}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section8"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Conclusion  
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                              In conclusion, the BambooHR Chatbot is a great way
                              to help employees keep up with their work and
                              personal lives.
                            </a>{" "}
                            It's easy to use and can be customized to meet your
                            needs. Its chat interface is reliable and
                            user-friendly, so you'll be able to get the most out
                            of it. So, if you're looking for an efficient way to
                            manage your employee's communication, the BambooHR
                            Chatbot is the perfect solution for you!
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Workativ enables you to build an AI bot for your
                            workplace in minutes, with no coding required.
                            Workativ is a free, no-code SaaS platform that
                            enables businesses to create, automate, and deploy
                            conversational AI chatbots in under an hour.
                            <a href="https://workativ.com/conversational-ai-platform/bamboohr-chatbot">
                              &nbsp;Try our BambooHR chatbot for FREE.
                            </a>
                          </p>
                        </div>

                        {/* Section 9*/}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-0 market_wrapper_page"
                          id="section9"
                        >
                          <h2>FAQs </h2>
                          <h3>
                            1. How can a BambooHR Chatbot enhance HR support
                            processes?
                          </h3>
                          <p>
                            Leveraging a BambooHR chatbot helps your employees
                            resolve many HR queries. Employees can solve
                            problems with leave management, workplace policy,
                            reimbursement policies, etc., to improve overall
                            efficiency and drive employee experience.
                          </p>
                          <h3>
                            2. What are the key benefits of implementing a
                            BambooHR Chatbot?
                          </h3>
                          <p>
                            Implementing a BambooHR chatbot can provide many
                            benefits for your organization. Through self-service
                            capabilities, you can efficiently implement workflow
                            automation for various queries. This enhances user
                            productivity by freeing up HR staff to focus on more
                            strategic functions.
                          </p>
                          <h3>
                            3. How does a BambooHR Chatbot contribute to HR
                            transformation?
                          </h3>
                          <p>
                            Using a BambooHR chatbot allows you to leverage the
                            power of Artificial Intelligence and Generative AI.
                            Your bot can apply NLP and NLU to provide custom
                            support for self-service and enhance the overall
                            employee experience. By reducing the dependency on
                            traditional support, BambooHR chatbots can drive HR
                            transformation.
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul className="section__ul">
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot

                        "
                              >
                                A Guide to Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/ms-teams-bot-for-hrms"
                              >
                                The Future of HRMs is Here with MS Teams Bots
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/top-8-human-resource-tasks-to-automate-for-better-employee-experience/"
                              >
                                Top 8 Human Resource Tasks to Automate for
                                better Employee Experience
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code">
      <h4>No Code - Free BambooHR Chatbot</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="bamboohr chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
